<template>
  <div class="rules-confirmation">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header d-flex">
          <div class="card-title">Правила инвестиционной платформы были изменены</div>
        </div>
        <div class="card-body">
          <p>
            На платформе Lendly с 18 июля 2024 действует
            <public-link to="https://cdn.lendly.ru/rules-2024-06-25.pdf">новая редакция Правил.</public-link><br/>
          </p>
          <p>
            С 18.07.2024 г. лимит в 600 тыс.руб. для неквалифицированных инвесторов (ФЛ) на вторичном рынке более не действует. <br />
            Физ. лицам для инвестирования на первичном рынке необходимо получить статус квалифицированного инвестора.
          </p>
          <div class="custom-control custom-checkbox mt-5">
            <input
                id="agreement"
                v-model="agreement"
                type="checkbox"
                class="custom-control-input"
                required
            />
            <label class="custom-control-label" for="agreement"></label>
            <public-link to="/page/using-rules">
              Согласен с правилами пользования платформой
            </public-link>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button
                type="submit"
                class="btn mt-5"
                :class="agreement ? 'btn-success' : 'btn-outline-secondary disabled'"
                :disabled="!agreement"
                @click="confirmRules"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {createProfileManager}  from "@/utils/di";
import {Processing, PublicLink} from "@/components/common";
import {DashboardEventType} from "../enums/DashboardEventType";

export default {
  components: {
    Processing,
    PublicLink
  },
  name: "RulesConfirmation",
  data() {
    return {
      agreement: false,
      processing: false
    }
  },
  inject: ["profile"],
  methods: {
    async confirmRules(){
      try {
        this.processing = true;
        const manager = await createProfileManager();
        await manager.confirmRules();
        this.$emit("dashboardEvent", DashboardEventType.RulesConfirmed);
        await this.$router.push({ name: "dashboard" });
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>

<style scoped>

</style>